<template>
  <div class="site-finance">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="站点账户管理" name="first">
        <en-table-layout
          toolbar
          pagination
          :tableData="pageData.data"
          @selection-change="handleSelectionChange"
          :sort-change-fn="sortChange"
          :loading="loading"
        >
          <template slot="toolbar">
            <el-form-item label="选择条件">
              <el-select
                style="width: 180px"
                size="medium"
                clearable
                v-model="params.mall_type"
                placeholder="请选择站点类型"
              >
                <!--                <el-option label="积分商城" :value="1"></el-option>-->
                <el-option label="普通现金商城" :value="2"></el-option>
                <el-option label="套餐卡商城" :value="3"></el-option>
                <el-option v-if="has_intelligent_card20" label="智能套餐卡商城" :value="4"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="关键词">
              <el-input
                placeholder="请输入关键词"
                size="medium"
                v-model="params.shopName"
              ></el-input>
            </el-form-item>

            <div class="col-auto">
              <el-button
                type="primary"
                :loading="importLoading"
                @click="GET_AccountList"
                size="small"
              >
                搜索
              </el-button>
            </div>

            <div class="col"></div>

            <div class="col-auto">
              <el-button
                type="primary"
                :loading="importLoading1"
                @click="downZd()"
                size="small"
              >
                导出报表
              </el-button>
            </div>
          </template>

          <template slot="table-columns">
            <el-table-column label="日期">
              <template slot-scope="scope">
                {{ scope.row.shop_createtime | unixToDate('yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column label="站点名称" width="300" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.shop_name }}</template>
            </el-table-column>
            <el-table-column label="站点类型">
              <template slot-scope="scope">
                <span v-if="scope.row.mall_type === 1">积分商城</span>
                <span v-if="scope.row.mall_type === 2">现金商城</span>
                <span v-if="scope.row.mall_type === 3">套餐卡商城</span>
                <span v-if="scope.row.mall_type === 4">智能套餐卡商城</span>
                <!-- {{ scope.row.mall_type === 1 ? '积分商城' : (scope.row.mall_type === 2 ? '现金商城' : '套餐卡商城') }} -->
              </template>
            </el-table-column>
            <el-table-column label="站点账户" sortable="custom" prop="balance_account">
              <template slot-scope="scope">
                {{ scope.row.balance_account | unitPrice('￥') }}
              </template>
            </el-table-column>
            <el-table-column label="站点现金账户" sortable="custom" prop="cash_account">
              <template slot-scope="scope">
                {{ scope.row.cash_account | unitPrice('￥') }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="addMoney(scope.row)"
                >
                  充值
                </el-button>
              </template>
            </el-table-column>
          </template>

          <el-pagination
            slot="pagination"
            @size-change="handlePageSizeChange"
            @current-change="handlePageCurrentChange"
            :current-page="pageData.page_no"
            :page-sizes="MixinPageSizes"
            :layout="MixinTableLayout"
            background
            :page-size="pageData.page_size"
            :total="pageData.data_total"
          ></el-pagination>
        </en-table-layout>
      </el-tab-pane>

      <el-tab-pane label="站点交易明细" name="second" lazy>
        <en-table-layout
          :tableData="pageData1.data"
          @selection-change="handleSelectionChange"
          :loading="loading"
        >
          <template slot="toolbar">
            <el-form-item label="起止日期">
              <el-date-picker
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                style="width: 220px;"
                v-model="daterangeDate"
                type="daterange"
                size="medium"
                @change="daterangeDateChange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="交易类型">
              <el-select
                style="width: 150px;"
                size="medium"
                v-model="params.flow_type"
                placeholder="请选择交易类型"
                clearable
              >
                <el-option
                  v-for="item in list1"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="关键词">
              <el-input
                placeholder="请输入关键字"
                size="medium"
                v-model.trim="params.sn_query"
              >
                <el-select
                  slot="prepend"
                  style="width: 120px;"
                  size="medium"
                  v-model="params.sn_type"
                  placeholder="请选择条件"
                >
                  <el-option
                    v-for="item in list2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-input>
            </el-form-item>

            <div class="col-auto">
              <el-button
                type="primary"
                :loading="importLoading"
                @click="GET_AccountList"
                size="small"
              >
                搜索
              </el-button>
            </div>

            <div class="col"></div>

            <div class="col-auto">
              <el-button
                type="primary"
                :loading="importLoading1"
                @click="downZd()"
                size="small"
              >
                导出报表
              </el-button>
            </div>
            <!-- <div class="toolbar-search">
                            <el-button type="primary" :loading="importLoading" @click="downZd()"  size="small">下载年账单</el-button>
            </div>-->
          </template>

          <template slot="table-columns">
            <el-table-column label="流水号" width="150" fixed="left">
              <template slot-scope="scope">
                {{ scope.row.sn }}
              </template>
            </el-table-column>
            <el-table-column label="站点名称" width="200" show-overflow-tooltip fixed="left">
              <template slot-scope="scope">
                {{ scope.row.user_name }}
              </template>
            </el-table-column>
            <el-table-column label="站点类型" width="150">
              <template slot-scope="scope">
                <span v-if="scope.row.mall_type === 1">积分商城</span>
                <span v-if="scope.row.mall_type === 2">现金商城</span>
                <span v-if="scope.row.mall_type === 3">套餐卡商城</span>
                <span v-if="scope.row.mall_type === 4">智能套餐卡商城</span>
                <!-- {{ scope.row.mall_type === 1 ? '积分商城' : (scope.row.mall_type === 2 ? '现金商城' : '套餐卡商城') }} -->
              </template>
            </el-table-column>
            <el-table-column
              label="交易类型"
              props="flow_type"
              :formatter="flow_type_value"
            ></el-table-column>
            <el-table-column label="交易时间" width="160">
              <template slot-scope="scope">
                {{ scope.row.create_date | unixToDate('yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column label="交易内容" width="400" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{ scope.row.order_content }}<br>
                <span v-if="scope.row.remark">备注信息:{{ scope.row.remark }}</span>
              </template>
            </el-table-column>
            <el-table-column label="站点账户变动" width="130" fixed="right">
              <template slot-scope="scope">
                {{ scope.row.revenue_expense_amount }}
              </template>
            </el-table-column>
            <el-table-column label="现金账户变动" width="130" fixed="right">
              <template slot-scope="scope">
                {{ scope.row.revenue_expense_cash }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.flow_type === 2"
                  @click="handleStockGoods(scope.row)"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </template>

          <el-pagination
            slot="pagination"
            @size-change="handlePageSizeChange1"
            @current-change="handlePageCurrentChange1"
            :current-page="pageData1.page_no"
            :page-sizes="MixinPageSizes"
            :layout="MixinTableLayout"
            background
            :page-size="pageData1.page_size"
            :total="pageData1.data_total"
          ></el-pagination>
        </en-table-layout>
      </el-tab-pane>
    </el-tabs>

    <!--充值-->
    <el-dialog title="充值" :visible.sync="isShowDisRebate">
      <div style="padding: 10px 20px; background-color: #f6f6f6;">
        充值为向该站点账户虚拟充值，需保证站点账户充足，站点才可下单。当前台用户下单时，若站点账户余额不足，订单将在站点端挂起
        （供应商将不可对该订单发货），需要企业向站点充值足够金额后，订单才可转为正常订单。
      </div>
      <el-form
        :model="disRebateData"
        label-width="100px"
        :rules="disRules"
        ref="disRebateData"
        status-icon
      >
        <el-form-item label="公司名称">
          <span>{{ disRebateData.shop_name }}</span>
        </el-form-item>
        <el-form-item label="预存金额" prop="price">
          <el-input
            v-model="disRebateData.price"
            style="width:250px"
            placeholder="请输入要充值的金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="marks">
          <el-input
            v-model="disRebateData.marks"
            style="width:250px"
            placeholder="请输入备注信息，不超30字"
          ></el-input>
        </el-form-item>
<!--        3.0需求去掉支付方式-->
<!--        <el-form-item label="支付方式：" prop="img">-->
<!--          <el-radio-group v-model="disRebateData.payment_plugin">-->
<!--            &lt;!&ndash; <el-radio label="1">-->
<!--              <img src="../../assets/images/wx.jpg" style="width:100px;height:50px;" />-->
<!--            </el-radio> &ndash;&gt;-->
<!--            <el-radio :label="2">-->
<!--              <img-->
<!--                src="../../assets/images/nowx.jpg"-->
<!--                style="width:100px;height:50px;"-->
<!--                alt=""-->
<!--              />-->
<!--            </el-radio>-->
<!--            &lt;!&ndash;    <el-radio label="3">-->
<!--              <img src="../../assets/images/underlinepay.png" style="width:100px;height:50px;" />-->
<!--            </el-radio> &ndash;&gt;-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
        <el-form-item
          label="凭证图片："
          prop="pay_img"
          v-if="disRebateData.payment_plugin === 3"
        >
          <el-upload
            class="site-logo"
            :action="MixinUploadApi"
            :show-file-list="false"
            :on-success="
                res => {
                  disRebateData.pay_img = res.url;
                }
              "
            :multiple="false"
          >
            <img
              v-if="disRebateData.pay_img"
              :src="disRebateData.pay_img"
              class="site-logo-img"
              alt=""
            />
            <i v-else class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            @click="lijiApply('disRebateData')"
            :disabled="disableButton"
          >立即支付
          </el-button
          >
        </el-form-item>
      </el-form>
      <div
        v-show="showPayBox && disRebateData.payment_plugin === 1"
        class="cashier-pay-box"
      >
        <div class="pay-item">
          <div
            class="pay-left"
            v-if="payment_plugin_id === 'accountPayPlugin'"
          >
            <p>使用账户余额支付</p>
            <div class="pc-pay-img"></div>
          </div>
          <div class="pay-left" v-else>
            <p v-if="payment_plugin_id !== 'weixinPayPlugin'">使用电脑支付</p>
            <div
              v-if="payment_plugin_id === 'weixinPayPlugin'"
              class="pc-pay-img"
            >
              <img src="../../assets/images/background-wechat.jpg" alt=""/>
            </div>
            <div v-else class="pc-pay-img"></div>
            <!-- 	<a class="pay-btn" href="javascript:;" @click="initiatePay(false, 'normal')">立即支付</a> -->
            <i
              v-if="payment_plugin_id === 'alipayDirectPlugin'"
              class="icon-or"
            ></i>
          </div>
          <div
            v-if="
                payment_plugin_id === 'alipayDirectPlugin' ||
                  payment_plugin_id === 'weixinPayPlugin'
              "
            class="pay-right"
          >
            <p v-if="payment_plugin_id === 'alipayDirectPlugin'">
              使用支付宝钱包扫一扫即可付款
            </p>
            <p v-if="payment_plugin_id === 'weixinPayPlugin'">
              使用微信钱包扫一扫即可付款
            </p>
            <div class="pay-qrcode" id="pay-qrcode">
              <iframe
                id="iframe-qrcode"
                width="200px"
                height="200px"
                scrolling="no"
                frameborder="0"
              />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_account from '@/api/account';
import * as API_Shop from '@/api/shop';
import {handleDownload} from '@/utils';
import {Foundation, RegExp} from '@/../ui-utils';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'goodsList',
  components: {EnTableLayout},
  data() {
    const checkMoney = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('预存金额不能为空'));
      }
      setTimeout(() => {
        if (!RegExp.money.test(value)) {
          callback(new Error('请输入正确的金额'));
        } else if (value > 1000000) {
          callback(new Error('超过最大充值金额'))
        } else {
          callback();
        }
      }, 500);
    };
    const checkRadio = (rule, value, callback) => {
      console.log(rule, value, callback);
      if (value < 1) {
        return callback(new Error('请选择支付方式'));
      } else {
        callback();
      }
    };
    return {
      has_intelligent_card20: false,
      activeName: 'first',
      txShow: false,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      importLoading1: false,
      payImgShow: false,
      showPayBox: false,
      payment_plugin_id: 'weixinPayPlugin',
      idsList: [],
      txInfo: {},
      txRules: {
        a4a4: [{
          required: true,
          message: '请输入礼包名称',
          trigger: 'blur'
        }]
      },
      list1: [],
      list2: [
        {
          value: 1,
          label: '财务流水号'
        },
        {
          value: 2,
          label: '订单号'
        },
        {
          value: 3,
          label: '交易内容'
        },
        {
          value: 4,
          label: '站点名称'
        }
      ],
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        mall_type: '',
        shopName: '',
        flow_type: '',
        sn_type: '',
        sn_query: ''
        // shop_name: "",
        // seller_name: "",
        // start_time: "",
        // end_time: "",

        // logi_id: "",
        // pay_type: "",
        // ca_type: 0
      },
      isShowDisRebate: false,
      /** 充值数据 */
      disRebateData: {
        shop_name: '',
        /** 充值价格 */
        price: '',

        /** 备注 */
        marks: '',
        /** 支付方式 */
        payment_plugin: 2,
        /** 服务类型  */
        service_type: 5,
        /**线下支付图片*/
        pay_img: ''
      },
      /** 分销返利校验规则 */
      disRules: {
        price: [
          {
            required: true,
            message: '预存金额不能为空',
            trigger: 'blur'
          },
          {
            validator: checkMoney,
            trigger: 'blur'
          }
        ],
        // marks: [
        //   {
        //     required: true,
        //     message: '备注信息不能为空',
        //     trigger: 'blur'
        //   },
        //   {
        //     required: true,
        //     validator: (rule, value, callback) => {
        //       if (value.length > 30) {
        //         return callback(new Error('备注信息不能超过30个'))
        //       } else {
        //         callback()
        //       }
        //     }
        //   }
        // ],
        payment_plugin: [
          {
            required: true,
            message: '支付方式不能为空',
            trigger: 'blur'
          },
          {
            validator: checkRadio,
            trigger: 'blur'
          }
        ],
        sortParams: {}
      },
      daterangeDate: '',

      /** 列表数据 */
      tableData: {},

      /** 列表分页数据 */
      pageData: {data: []},
      pageData1: {data: []},

      /** 快递列表 */
      logiList: [],
      gateway_url: '',
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      /** 禁止button双击 */
      disableButton: false,
    };
  },
  activated() {
    // var {type} = this.$route.query;
    // this.activeName = type ? type : 'first';
    // this.GET_AccountList();
  },
  mounted() {
    API_Shop.getShopLv().then(res => {
      this.has_intelligent_card20 = res.data.has_intelligent_card20 === 1 ? true : false
    })
    if (sessionStorage.getItem('orderListActiveName') === 'second') {
      this.activeName = sessionStorage.getItem('orderListActiveName')
    } else {
      var {type} = this.$route.query;
      this.activeName = type ? type : 'first';
    }
    console.log(this.activeName)
    this.GET_AccountList();
    this.GET_getFlowTypeList();
  },
  watch: {
    activeName: {
      handler: 'GET_AccountList'
    }
  },
  destroyed() {
    sessionStorage.removeItem('orderListActiveName')
  },
  methods: {
    /**获取流水类型信息 */
    GET_getFlowTypeList() {
      API_account.getFlowTypeList({use_type: 2}).then(response => {
        this.list1 = response;
      });
    },
    flow_type_value(row, column) {
      let obj = this.list1.find((value, index, arr) => {
        return row.flow_type == value.code;
      });
      if (obj) {
        return obj.name;
      }
    },
    daterangeDateChange(val) {
      this.params.start_time = val ? val[0] / 1000 : '';
      this.params.end_time = val ? val[1] / 1000 : '';
    },
    // 充值
    addMoney(row) {
      console.log(row);
      // API_account.getShopmsg().then(response => {
      //   console.log(response)
      //   console.log(this.disRebateData,'this.disRebateData')

      // });
      this.disRebateData.price = '';
      // this.disRebateData.payment_plugin = 2;
      this.disRebateData.service_type = 5;
      this.disRebateData.pay_img = '';
      this.disRebateData.marks = '';
      this.disRebateData.shop_name = row.shop_name;
      this.disRebateData.shop_id = row.shop_id;
      this.isShowDisRebate = true;
      this.showPayBox = false;
    },
    lijiApply(formName) {
      this.disableButton = true
      if (this.disRebateData.payment_plugin === 0) {
        this.$message.error('请选择支付方式');
        return;
      }
      if (this.disRebateData.payment_plugin === 3 && this.disRebateData.pay_img === '') {
        this.$message.error('请上传凭证图片');
        return;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          var obj = {};
          obj.price = this.disRebateData.price;
          obj.service_type = this.disRebateData.service_type;
          obj.shop_id = this.disRebateData.shop_id;
          obj.marks = this.disRebateData.marks;
          // 创建服务订单
          API_account.createOrder(obj).then(response => {
            console.log('this.disRebateData.payment_plugin=' + this.disRebateData.payment_plugin);
            // this.isShowDisRebate = false;
            // this.$message.success("当前商品分销返利金额修改成功");
            if (this.disRebateData.payment_plugin === 1) {
              // 获取支付的二维码
              this.weixinPayPlugin(response);
            } else if (this.disRebateData.payment_plugin === 2) {
              //余额支付
              this.shopBalancePlugin(response);
            } else {
              //线下支付
              this.payDown(response);
            }
            this.disableButton = false
          });
        } else {
          this.disableButton = false
        }
      });
    },
    //微信支付
    weixinPayPlugin(sn) {
      var obj2 = {};
      obj2.payment_plugin_id = 'weixinPayPlugin';
      obj2.client_type = 'PC';
      obj2.pay_mode = 'ar';
      this.showPayBox = true;
      // 支付宝、微信支付
      this.$nextTick(() => {
        document.getElementById(
          'pay-qrcode'
        ).innerHTML = `<iframe id="iframe-qrcode" width="200px" height="200px" scrolling="no" frameborder="0"></iframe>`;
        // 如果是普通支付方式，
        API_account.paymentServiceOrder(sn, obj2).then(response => {
          console.log(response.gateway_url);
          this.gateway_url = response.gateway_url;
          let $formItems = '';
          response.form_items &&
          response.form_items.forEach(item => {
            $formItems += `<input name="${item.item_name}" type="hidden" value='${item.item_value}' />`;
          });
          $formItems += `<input name="path" type="hidden" value='/finance/site-finance' />`;
          let $form = `<form action="${response.gateway_url}" method="post">${$formItems}</form>`;
          const qrIframe = document.getElementById('iframe-qrcode')
            .contentWindow.document;
          qrIframe.getElementsByTagName('body')[0].innerHTML = $form;
          qrIframe.forms[0].submit();
          // 			  API_account.callBack(res.return_url).then(data => {
          // 					this.GET_AccountList();
          // 					this.GET_getFlowTypeList();
          // 					this.isShowDisRebate = false;
          // 			  });
        });
      });
    },
    //余额支付
    shopBalancePlugin(sn) {
      let order_params = {
        payment_plugin_id: 'shopBalancePlugin',
        pay_mode: 'normal',
        client_type: 'PC',
        trade_type: 'service'
      };
      API_account.paymentServiceOrder(sn, order_params).then(res => {
        console.log(res, 'this.order_params');
        let callBackParams = {
          pay_order_no: res.pay_order_no,
          need_pay_money: res.need_pay_money,
          out_trade_no: res.out_trade_no
        };
        API_account.callBack(res.call_back_url, callBackParams).then(resp => {
          this.isShowDisRebate = false;
          this.$message.success('支付成功');
          this.GET_AccountList();
          this.GET_getFlowTypeList();
        });
      });
    },
    //线下支付
    payDown(sn) {
      let callBackParams = {
        pay_img: this.disRebateData.pay_img
      };
      API_account.payDown(callBackParams, sn).then(res => {
        this.isShowDisRebate = false;
        this.$message.success('操作成功');
        this.GET_AccountList();
        this.GET_getFlowTypeList();
      });
    },
    downZd() {
      this.importLoading1 = true;
      let params = {
        ...this.params
      };
      params.page_no = 1;
      params.page_size = 9999;
      if (this.activeName === 'first') {
        API_account.getShopChildrenList({...this.params,...this.sortParams}).then(response => {
          response.data.forEach(item => {
            item.shop_createtime = Foundation.unixToDate(
              item.shop_createtime,
              'yyyy-MM-dd hh:mm:ss'
            );
            switch (item.mall_type) {
              case 1:
                item.mall_type = '积分商城'
                break;
              case 2:
                item.mall_type = '现金商城'
                break;
                  case 3:
                item.mall_type = '套餐卡商城'
                break;
                  case 4:
                item.mall_type = '智能套餐卡商城'
                break;
            }
            // item.flow_type = this.list1.find((value, index, arr) => {
            //   return item.flow_type == value.code;
            // }).name;
          });
          let tHeaders = [
            '日期',
            '站点名称',
            '站点类型',
            '站点账户变动',
            '站点现金账户变动'
          ];
          let filterVals = [
            'shop_createtime',
            'shop_name',
            'mall_type',
            'balance_account',
            'cash_account'
          ];
          handleDownload(response.data, tHeaders, filterVals, '站点账户管理');
          this.importLoading1 = false;
        });
      } else {
        API_account.getAccountFlowPages(params).then(response => {
          response.data.forEach(item => {
            item.create_date = Foundation.unixToDate(
              item.create_date,
              'yyyy-MM-dd hh:mm:ss'
            );
            switch (item.mall_type) {
              case 1:
                item.mall_type = '积分商城'
                break;
              case 2:
                item.mall_type = '现金商城'
                break;
                  case 3:
                item.mall_type = '套餐卡商城'
                break;
                  case 4:
                item.mall_type = '智能套餐卡商城'
                break;
            }
            item.flow_type = this.list1.find((value, index, arr) => {
              return item.flow_type == value.code;
            }).name;
            console.log(item.flow_type)
          });
          let tHeaders = [
            '流水号',
            '交易时间',
            '站点名称',
            '站点类型',
            '交易类型',
            '交易内容',
            '站点账户变动',
            '站点现金账户变动'
          ];
          let filterVals = [
            'sn',
            'create_date',
            'user_name',
            'mall_type',
            'flow_type',
            'order_content',
            'revenue_expense_amount',
            'revenue_expense_cash'
          ];
          handleDownload(response.data, tHeaders, filterVals, '站点交易明细');
          this.importLoading1 = false;
        });
      }
    },
    sortChange(column){
      // debugger
      let val = column.order === 'ascending' ? 'ASC' : 'DESC'
      let k = { 'balance_account': 'account_sort', 'cash_account': 'account_cash_sort' }[column.prop]
      this.sortParams = { [k]: val }
      this.GET_AccountList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 分页大小发生改变 */
    handlePageSizeChange1(size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange1(page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keyword: data
      };
      this.GET_AccountList();
    },

    GET_AccountList() {
      this.loading = true;
      if (this.shopInfo.shop_type === 2) {
        this.params.settle_status = 0;
      } else {
        this.params.shop_settle_status = 0;
      }
      if (this.activeName === 'first') {
        delete this.params.account_type;
        API_account.getShopChildrenList({ ...this.params, ...this.sortParams }).then(response => {
          this.loading = false;
          this.pageData = response;
        });
      } else {
        this.params.account_type = 1;
        API_account.getAccountFlowPages(this.params).then(response => {
          this.loading = false;
          this.pageData1 = response;
        });
      }
    },

    handleSelectionChange(val) {
      let ids = [];
      val.forEach(item => {
        ids.push(item.order_id);
      });
      this.idsList = ids;
    },
    settlement() {
      if (this.idsList.length) {
        API_account.checkaccountAdd({
          orderId: this.idsList,
          caDirection: this.params.ca_type
        }).then(res => {
          this.$message.success('发起结算成功');
          this.GET_AccountList();
        });
      } else {
        this.$message.error('请勾选数据');
      }
    },
    handleStockGoods(row) {
      let route = this.$route.path.split('/')[2];
      if (row.mall_type === 4) {
        this.$router.push({
          'path': `/order/order-list/ai-combo-order-detail/${row.trade_sn}?type=1`
        })
      } else {
        this.$router.push({
          "path": `${route}/orderDetail/${row.trade_sn}?type=${this.activeName}`
        })
        // this.$router.push({path: `/finance/${route}/detail/${row.id}`});
      }
    }
  }
};
</script>

<style scoped lang="scss">
.site-finance {
  background: #fff;

  .el-tab-pane {
    height: calc(100vh - 140px);
    min-height: 640px;
  }
}

.site-logo .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: #409eff;
  }
}

.site-logo-img {
  width: 100%;
}

.cashier-pay-box {
  border: 1px solid #e1e1e1;
  background: #f4f4f4;
  margin: 0 0 40px 0;
  padding-top: 3px;

  .pay-item {
    display: flex;
    justify-content: center;
    margin: 0 3px 3px 3px;
    background: #fff;
    overflow: hidden;
    height: 335px;

    .pay-left {
      width: 471px;
      float: left;
      height: 310px;
      position: relative;

      p {
        width: 450px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        margin: 25px 0 0 0;
        font-size: 16px;
      }

      .pc-pay-img {
        height: 92px;
        margin-left: 150px;
        margin-top: 48px;
        width: 165px;
        background: url(../../assets/images/icons-cashier.png) no-repeat 0 -1417px;
      }

      .pay-btn {
        width: 180px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background: #fff;
        display: block;
        margin: 30px auto 0 auto;
      }

      .icon-or {
        display: block;
        background: url(../../assets/images/icons-cashier.png) no-repeat -212px -1417px;
        height: 41px;
        left: 464px;
        position: absolute;
        top: 130px;
        width: 31px;
      }
    }

    .pay-right {
      float: left;
      border-left: 1px solid #f4f4f4;

      p {
        width: 472px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        margin: 25px 0 0 0;
        font-size: 16px;
      }

      .pay-qrcode {
        margin: 20px auto;
        height: 200px;
        width: 200px;
        overflow: hidden;
      }
    }
  }
}

::v-deep .el-tabs__header.is-top {
  margin-bottom: 0;
}
</style>
